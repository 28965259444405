import { ResetPageReason, ThankYouAnimationType } from './webgiving-generated';
import { ModalType } from './modals/modal-type';

export namespace WebGivingUserAction {
	export class SelectInitialPage {
	}
	export class VerifyApplePaySDK {
	}
	export class SubmitCurrentPage {
	}

	export class PreviousPage {
	}

	export class SignOut {
	}

	export class RequestConfirmationCode {
		constructor(public useVoiceCall?: boolean) { }
	}

	export class RequestResendOfConfirmationCode {
		constructor(public useVoiceCall?: boolean) { }
	}

	export class ChangeBankDetails {
	}

	export class ChangePaymentDetails {
	}

	export class ChangeMobileNumber {
	}

	export class NewPaymentMethod {
	}

	export class BackToConfirmGift {
	}

	export class ResetPageBecauseOfError {
		constructor(public reason?: ResetPageReason) { }
	}

	export class OpenModalDialog {
		constructor(public modalType: ModalType) { }
	}

	export class LogCryptoEngagement {
	}

	export class LogCryptoGiving {
	}

	export class LogStockEngagement {
	}

	export class LogStockGiving {
	}

	export class ChangePayerCoversFees {
		constructor(public payerCoversFees: boolean) { }
	}

	export class RecordPageStats {
		constructor(public returnUrl?: string) { }
	}

	export class GiveAgain {
	}

	export class SetRecurringSuggestion {
	}

	export class RecordRecurringSuggestionView {
	}

	export class FetchAppContentCard {
	}
	export class UpdateFastModeEnabled {
		constructor(public mode: boolean) { }
	}

	export class AnimateThankYou {
		constructor(public animationType: ThankYouAnimationType) { }
	}
	export class MakeApplePay {
		constructor(public amountWithFees?: number) { }
	}
}

export type WebGivingUserAction = typeof WebGivingUserAction[keyof typeof WebGivingUserAction]['prototype'];
